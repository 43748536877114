<template>
  <div class="template-shop">
    <div class="template-header" :style="{'border-radius': isContract ? '6px' : '6px 6px 0px 0px'}">
      <svg-icon :icon-class="'search'" style="font-size: 18px;color:#3D4E69" />
      <a-input class="search-input" v-model="searchWord" placeholder="搜索应用模版名称关键字" @change="changeWord"></a-input>
    </div>
    <div class="template-cate">
      <div class="scene">
        <span class="title">场景分类</span>
        <span v-for="(item, index) in templateCate" :key="index" :class="[selectCate === item.catagory && 'selected', 'item']" @click="select(item, 'cate')">{{ item.name }}</span>
      </div>
      <div class="industry">
        <span class="title">行业分类</span>
        <span v-for="(item, index) in templateTag" :key="index" :class="[selectTag === item.tag && 'selected', 'item']" @click="select(item, 'tag')">{{ item.name }}</span>
        <span class="title mgl20">模板分类</span>
        <span v-for="(item) in templateTypeList" :key="item.value" :class="[selectType === item.value && 'selected', 'item']" @click="select(item, 'type')">{{ item.label }}</span>
      </div>
      <!-- <div class="industry">
        <span class="title">行业分类</span>
        <span v-for="(item, index) in templateTag" :key="index" :class="[selectTag === item.tag && 'selected', 'item']" @click="select(item, 'tag')">{{ item.name }}</span>
      </div> -->
    </div>
    <div class="flex-bewteen-container template-type">
      <div>选择一个模板</div>
      <div><a-checkbox v-model="isMyCollect" @change="search">仅显示我的收藏</a-checkbox></div>
    </div>
    <TemplateList />
      <!-- 新建部署任务 -->
    <a-modal class="task-model" v-if="visible" title="" :visible="visible" :maskClosable="false" :closable="false" :width="clientWidth < 1700 ? 1280 : 1326"  :confirm-loading="confirmLoading" @cancel="handleCancel" :footer="null">
      <CreateNewtask taskType="template" @refreTemplate="search" />
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CreateNewtask from "@/components/createTask/deploystep/newCreateTask.vue";
import TemplateList from './newTemplateShop/templateList.vue'
import _ from "lodash";
const clientWidth = document.documentElement.clientWidth

export default {
  data() {
    return {
      clientWidth,
      isContract: false,
      searchWord: "",
      templateCate: [],
      templateTag: [],
      templateTypeList: [
      {
        label: '全部',
        value: ''
      },{
        label: '任务',
        value: '0'
      },{
        label: '流程',
        value: '1'
      }],
      selectCate: '',
      selectTag: '',
      selectType: '',
      checked: false,
      isMyCollect: false,
      visible: false,
      taskModalTitleL: '新建任务',
      confirmLoading: false,
    };
  },
  components: { TemplateList, CreateNewtask },
  watch: {
  },
  mounted() {
    this.getCatagories();
    this.getTagsList();
  },
  provide() {
    return {
      createTask: this.createTask,
      handleCancel: this.handleCancel,
      onlyCancel: this.handleCancel,
      onSearchByCreateTime: null
    };
  },
  methods: {
    ...mapActions("task", ["setTaskInfo", "setStepInfo"]),

    handleCancel(e) {
      this.visible = false;
    },
    async getTaskDetail (taskId) {
      let res = await this.$axiosGet(
        global.API.curdTaskTemplate + `${taskId}/`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
          this.taskModalTitle = "新建任务";
          res.data.operations.forEach(item => {
            if (item.application_id) {
                item['currentStepsIndex'] = 1
              }
              if (item.id) {
                item['currentStepsIndex'] = 2
              }
              if (item.application_id && item.id) {
                item['currentStepsIndex'] = res.data?.type !== '1' ? 4 : 2
              }
          })
          const taskInfo = {
            ...res.data,
            taskTemplateDetail: {
              operations: res.data.operations,
              configs: res.data.configs,
              notify: res.data.notify
            },
          };
          this.setTaskInfo(taskInfo);
          this.visible = true;
      } else {
        this.$message.error('获取模板详情失败')
      }
    },
    // 获取流程详情
    async getFlowDetail (taskId) {
      let res = await this.$axiosGet(
        global.API.curdTaskTemplate + `${taskId}/`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        let stepInfo = {
            name: res.data.name,
            template_id: res.data.id,
            taskTemplateDetail: {
              operations: res.data?.operations || [],
              configs: res.data?.configs || [],
              notify: res.data?.notify || [],
              stepInfo: {
                properties: {
                  currentStepIndex: 0
                }
              }
            },
          };
          this.setStepInfo(stepInfo);
          this.$router.push({
            path: '/task-manage/pipeline-new',
            query: {
              from: 'template'
            }
          })
      } else {
        this.$message.error('获取模板详情失败')
      }
   },
    createTask(obj) {
      if (obj.task_type && Number(obj.task_type) === 1) { 
        this.getFlowDetail(obj.id)
      } else {
        this.getTaskDetail(obj.id)
      }
    },
    debounce: _.debounce(function () {
      this.search()
    }, 1000),
    changeWord () {
      this.debounce()
    },
    search () {
      this.$EventBus.$emit("search", this.searchWord, this.selectCate, this.selectTag, this.isMyCollect, this.selectType);
    },
    select (item, type) {
      type === 'cate' ? this.selectCate = item.catagory : type === 'tag' ? this.selectTag = item.tag : this.selectType = item.value
      this.search()
    },
    getCatagories() {
      this.$axiosGet(global.API.getCatagories, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = res.data.results;
          this.templateCate.unshift({
            catagory: '',
            name: '全部'
          })
        }
      });
    },
    getTagsList() {
      this.$axiosGet(global.API.getTagsList, {}).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateTag = res.data || [];
          this.templateTag.unshift({
            tag: '',
            name: '全部'
          })
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.template-shop {
  height: calc(100vh - 48px);
  background: #F7F8FA;
  padding: 28px 0 28px 11.2%;
  .template-header {
    display: flex;
    align-items: center;
    margin-right: 11.2%;
    // width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    background: #fafbfc;
    border: 1px solid rgba(207, 213, 222, 1);
    .search-input {
      border: none;
      background: #FAFBFC;
      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .template-cate {
    background: #FFFFFF;
    border: 1px solid rgba(207,213,222,1);
    border-radius: 0px 0px 6px 6px;
    border-top: none;
    margin-right: 11.2%;
    .scene,
    .industry {
      padding: 16px 0;
      margin: 0 20px;
      font-size: 14px;
      color: #34343C;
      letter-spacing: 0;
      font-weight: 400;
      .title {
        font-size: 14px;
        color: #1d202d;
        letter-spacing: 0;
        font-weight: 600;
        margin-right: 20px;
      }
      .item {
        cursor: pointer;
        padding: 5px 18px;
      }
      .selected {
        background: rgba(57,116,244,0.10);
        border-radius: 4px;
        color: @primary-color;
      }
    }
    .scene {
      border-bottom: 1px dashed rgba(207,213,222,1);;
    }
  }
  .template-type {
    margin: 12px 11.2% 0 0;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #34343C;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-21 11:24:58
 * @LastEditTime: 2024-01-22 17:53:09
 * @FilePath: \awx-ui\src\pages\templateShop\newTemplateShop\templateCard.vue
-->
<template>
  <div class="template-card-page"  @click.stop="addTask" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <svg-icon v-if="templateItem.is_fav" class="collect" style="font-size: 24px" :icon-class="hoverFlag ? 'collect-icon-select' : 'collect-icon'" />
    <div class="top">
      <div class="steps">
        <div :class="['steps-item', (operations.length < 3 && index === 0) && 'first-item', (operations.length < 3 && index === 1) && 'second-item', index === operations.length -1 && 'last-item']" v-for="(item, index) in operations" :key="index">
          <div class="img" v-if="index === 0 && item.application_id === 'schedule'"><img :src="handlerScheduleIcon(item.id)" alt /></div>
          <div class="img" v-if="index === 0 && item.application_id !== 'schedule'"><img :src="item.icon" alt /></div>
          <div class="img num" v-if="operations.length > 2 && index === 1 && item.length > 1">{{ item.length }}</div>
          <!-- special -->
          <div class="img" v-if="operations.length > 2 && index === 1 && item.length === 1"><img :src="item[0].icon" alt /></div>
          <div class="img" v-if="operations.length > 1 && index === operations.length - 1"><img :src="item.icon" alt /></div>
          <div class="arrow-container" v-if="index < operations.length - 1"><svg-icon class="arrow" icon-class="arrow" /></div>
        </div>
      </div>
      <div style="height: 1px;background: #EDEFF3;margin: 0 20px; width: calc(100% - 40px);"></div>
    </div>
    <div class="name ell">{{ templateItem.name }}</div>
    <div class="btn"  @click.stop="addTask">立即使用</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      vmTemplateItem: {},
      hoverFlag: false
    };
  },
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  inject: ['createTask'],
  computed: {
    ...mapState('schedule', ['scheduleDict']),
    operations() {
      let arr =_.cloneDeep(this.templateItem?.operations || [])
      if (arr.length <= 2) {
        return arr; // 如果数组长度小于等于3，则直接返回原数组
      } else {
        const middleItems = arr.splice(1, arr.length - 2); // 获取中间项，并从原数组中删除这些项
        return [arr[0], middleItems, arr[arr.length - 1]];
      }
    }
  },
  components: {},
  mounted() {
  },
  methods: {
    mouseenter () {
      this.hoverFlag = true
    },
    mouseleave() {
      this.hoverFlag = false
    },
    handlerScheduleIcon (id) {
      const obj = this.scheduleDict.find(item => item.id === id) 
      const icon = obj?.icon || ''
      return icon
    },
    addTask() {
      this.createTask(this.templateItem)
    },
  }
};
</script>
<style scoped lang='less'>
.template-card-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18.626%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 200px;
  background: #ffffff;
  border: 1px solid rgba(207, 213, 222, 1);
  cursor: pointer;
  border-radius: 6px;
  margin: 12px 25px 12px 0;
  padding: 1px;
  box-shadow: 0px 2px 10px 0px rgba(65,81,107,0.1);
  &:nth-child(5n) {
    margin-right: 0;
  }
  .top {
    background: rgb(249, 251, 253);
  }
  .collect {
    position: absolute;
    top: -1px;
    right: -1px;
    // font-size: 60px;
    z-index: 10;
  }
  .name {
    text-align: center;
    font-size: 17px;
    color: #34343C;
    letter-spacing: 0;
    font-weight: 550;
    padding: 0 28px;
  }
  .steps {
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #EDEFF3;
    padding: 20px 20px 10px;
    justify-content: space-between;
    &-item {
      display: flex;
      align-items: center;  
      width: 40%;
      .img {
        width: 42px;
        height: 42px;
        padding: 0;
        background: #FFF; // 这个是图标的颜色定义
        text-align: center;
        line-height: 18px;
        border-radius: 8px;
      } 
      .num {
        font-size: 24px;
        color: #3974F4;
        letter-spacing: 0;
        font-weight: 600;
      }
      img {
        width: 100%;
        border-radius: 6px;
      }
      .arrow-container {
        flex: 1;
        text-align: center;
      } 
      .arrow {
        color:  #CFD5DE;
      }
      &:nth-child(n) {
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          // border: 1px solid rgba(207,213,222,1);
          box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
          // padding: 10px;
        }
        img {
          width: 36px;
        }
      }
      .special {
        padding: 0!important;
        background: #fff!important;
        border-radius: 4px!important;
        box-shadow: none!important;
        border: none!important;
        img {
          width: 100%!important;
          // height: 100%!important;
          border-radius: 6px;
      }
      }
    }
    .last-item {
      width: 20%;
      justify-content: flex-end;
    }
    .first-item {
      justify-content: space-between;
      width: 60%;
      .arrow-container {
        text-align: right;
        padding-right: 16px;
      }
    }
    .second-item {
      width: 40%;
      justify-content: flex-end;
    }
  }

  .btn {
    width: calc(100% - 40px);
    height: 32px;
    background: #F4F8FC;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    color:@primary-color;
    margin: 0 20px 20px;
  }
  &:hover {
    background: #F1F5FD;
    border: 1px solid rgba(57,116,244,1);
    .btn {
      color: #fff;
      background: #3974F4;
    }
    .arrow {
      color: #83A6F2;
    }
    .top {
      background: rgba(57,116,244,0.05);
    }
  }
}
@media (max-width: 1910px) {
  .template-card-page { 
      width: 18.626%;
      margin: 12px 1.7% 12px 0;
      .steps {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        justify-content: center;
        &-item {
          display: flex;
          align-items: center;  
          .img {
            width: 30px;
            height: 30px;
            padding: 0px;
            background: #FFF; // 这个是图标的颜色定义
            border-radius: 4px;
            text-align: center;
            line-height: 14px;
          } 
          .num {
            font-size: 20px;
            color: #3974F4;
            letter-spacing: 0;
            font-weight: 600;
          }
          img {
            width: 100%;
            border-radius: 6px;
          }
          .arrow {
            margin: 0 11px;
            color:  #CFD5DE;
            font-size: 12px;
          }
          &:nth-child(2n) {
            .img {
              background: #fff;
              border: 1px solid rgba(207,213,222,1);
              padding: 8px;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      &:hover {
        background: #F1F5FD;
        border: 1px solid rgba(57,116,244,1);
        .btn {
          color: #fff;
          background: #3974F4;
        }
        .arrow {
          color: #83A6F2;
        }
        .top {
          background: rgba(57,116,244,0.05);
        }
      }
   }
}
</style>
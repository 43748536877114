<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2023-12-18 11:06:15
 * @FilePath: \awx-ui\src\pages\templateShop\newTemplateShop\templateList.vue
-->
<template>
  <a-spin :spinning="spinning">
    <div class="template-list">
      <div v-if="templateList.length" class="card-list">
        <template v-for="(item) in templateList">
          <TemplateCard :key="item.id" :templateItem="item" />
        </template>
      </div>
      <NODATA v-else type="normal" />
    </div>
  </a-spin>
</template>

<script>
import TemplateCard from "./templateCard.vue";
import NODATA  from '@/components/createTask/components/noData.vue'

export default {
  name: "TemplateList",
  data() {
    return {
      templateList: [],
      cateType: 'all',
      spinning: false
    };
  },
  components: { TemplateCard, NODATA },
  watch: {
  },
  computed: {
  },
  methods: {
    getTemplateList(searWoord, selectCate, selectTag, isMyCollect, taskType) {
      this.spinning = true
      let params = {
        // type: this.$route.name === '我的模板' ? 1 : 0,
      };
      if (isMyCollect) params.is_fav = 1
      if (this.cateType === "all") params.keyword = searWoord || '';
      params.tag = selectTag || '';
      params.catagory = selectCate || '';
      params.task_type = taskType || '';
      params.page_size = 100
      params.page = 1
      this.$axiosGet(global.API.getTemplateList, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateList = res.data.results.map((item) => {
            return {
              ...item
            };
          });
        }
      });
    },
  },
  mounted() {
    this.getTemplateList();
    const that = this
    that.$EventBus.$on("search", (searWoord, selectCate, selectTag, isMyCollect, taskType) => {
      that.getTemplateList(searWoord, selectCate, selectTag, isMyCollect, taskType);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("search");
  },
};
</script>

<style lang="less" scoped>
.template-list {
  text-align: left;
  .current-selected {
    margin: 0 0 16px;
    .title {
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 12px;
    }
    /deep/ .ant-tag {
      border-radius: 4px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 400;
      background: rgb(225, 232, 247);
      .anticon-close {
        margin-left: 20px;
        width: 8px;
        height: 8px;
        margin-right: 4px;
        color: #777c88;
      }
    }
  }
  .card-list {
    display: flex;
    padding-right: 11.2%;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: calc(100vh - 284px);
    overflow-y: auto;
  }
}
</style>
